import { set } from 'vue'
import Parse, { normalize } from '@/plugins/parse'

export const state = () => ({
  consentId: window.localStorage.getItem('consentId'),
  dialog: null,
  createdAt: null,
  services: {},
  current: {},
  activity: {}
})

export const getters = {
  status: state => Object.values(state.current).every(Boolean)
    ? 'Alles akzeptiert'
    : Object.values(state.current).some(Boolean)
      ? 'Einige akzeptiert'
      : 'Nichts akzeptiert'
}

export const mutations = {
  SET_CONSENT(state, { services, consent: { createdAt, uuid, current, activity } }) {
    services && set(state, 'services', services)
    window.localStorage.setItem('consentId', uuid)
    set(state, 'consentId', uuid)
    set(state, 'createdAt', createdAt)
    set(state, 'current', current || {})
    set(state, 'activity', activity || {})
  },
  SHOW_DIALOG(state) {
    state.dialog = true
  },
  HIDE_DIALOG(state) {
    state.dialog = false
  }
}

export const actions = {
  async init({ rootGetters, state, commit }) {
    if (rootGetters.isIntern) { return }
    commit('SET_CONSENT', await Parse.Cloud.run('cookie-consent', { consentId: state.consentId }).then(normalize))
  },
  async submit({ state, commit }, update) {
    commit('SET_CONSENT', await Parse.Cloud.run('cookie-consent', { consentId: state.consentId, update }).then(normalize))
  },
  showDialog({ commit }) {
    commit('SHOW_DIALOG')
  },
  hideDialog({ commit }) {
    commit('HIDE_DIALOG')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
