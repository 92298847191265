import Vue from 'vue'
import Router from 'vue-router'
import getRoutes from './routes'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import { wait } from '@/utils/helpers'

Vue.prototype.$lastFullPath = null

Vue.use(Router)

// The middleware for every page of the application.
const globalMiddleware = []

/**
 * Create a new router instance.
 *
 * @return {Routerqy}
 */
const createRouter = (routes) => {
  const router = new Router({
    mode: 'history',
    scrollBehavior,
    routes
  })
  router.beforeEach(beforeEach)
  router.afterEach(afterEach)
  return router
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
const resolveMiddleware = (requireContext) => {
  return requireContext.keys()
    .map(file =>
      [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((guards, [name, guard]) => (
      { ...guards, [name]: guard.default }
    ), {})
}

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
  require.context('./middleware', false, /.*\.js$/)
)

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
const scrollBehavior = (to, from, savedPosition) => {
  if (to.meta.isDialog || from.meta.isDialog) {
    return
  }
  const reserved = ['cube', 'lead', 'booking', 'page']
  if (to.hash === '#filter' || reserved.some(r => to.hash.startsWith(`#${r}`))) {
    return
  }
  if (from.hash === '#filter' || reserved.some(r => from.hash.startsWith(`#${r}`))) {
    return
  }
  if (to.name !== from.name && !to.hash && !savedPosition?.y) {
    vuetify.framework.goTo(0)
    return
  }
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async() => {
    while (store.state.marklist.loading) { await wait(1000) }
    if (to.hash) {
      try {
        vuetify.framework.goTo(to.hash)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }
    savedPosition?.y && vuetify.framework.goTo(savedPosition.y)
  })
}
/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
const beforeEach = async(to, from, next) => {
  if (to.query?.cachebuster) {
    delete to.query.cachebuster
    return next(to)
  }
  if (from?.meta?.onNavigate) {
    await from.meta.onNavigate()
    return next(from)
  }
  // Get the middleware for all the matched components.
  const middleware = getMiddleware(to)

  // Call each middleware.
  await callMiddleware(middleware, to, from, (...args) => {
    next(...args)
  })
}

const afterEach = (to, from) => {
  store.dispatch('setTitle', to.meta.title)
  Vue.prototype.$lastFullPath = from?.name ? from.fullPath : null
}

/**
 * Merge the the global middleware with the route middleware.
 *
 * @param  {Array} to
 * @return {Array}
 */
const getMiddleware = (to) => {
  const middleware = [...globalMiddleware]
  to.matched.filter(record => record.meta && record.meta.middleware).forEach((record) => {
    if (Array.isArray(record.meta.middleware)) {
      middleware.push(...record.meta.middleware)
    } else {
      middleware.push(record.meta.middleware)
    }
  })
  return middleware
}

/**
 * Call each middleware.
 *
 * @param {Array} middleware
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
const callMiddleware = async(middleware, to, from, next) => {
  const stack = middleware.reverse()
  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args)
    }
    const middleware = stack.pop()
    if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next)
    } else {
      throw new Error(`Undefined middleware [${middleware}]`)
    }
  }
  await _next()
}
const routes = getRoutes()
const router = createRouter(routes)

export default router
