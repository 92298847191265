import Vue from 'vue'
import Parse from 'parse'
import { isNavigationFailure } from 'vue-router'
import store from '@/store'
import Sentry from '@/plugins/sentry'

// Custom error classes
export class FormError extends Error {
  constructor(message) {
    super(message)
    this.name = 'FormError'
  }
}

const extractErrorMessage = function(error) {
  if (!error) { return }
  if (typeof error === 'string') { return error }
  return extractErrorMessage(error.message || error.statusText || error.codeName || error.status)
}

export const normalizeErrorMessage = function(error) {
  const errorMessage = extractErrorMessage(error)
  if (!errorMessage) { return }
  if (error.code === Parse.Error.CONNECTION_FAILED) {
    return 'Keine Verbindung zum Server'
  }
  if (error.code === 413 || errorMessage === 'request entity too large') {
    return 'Datei zu groß'
  }
  if (errorMessage.includes('WebGLRenderingContext') || errorMessage.includes('hitRenderTarget_')) {
    return 'WebGL Fehler: Bitte prüfen Sie Ihre browser WebGL settings oder versuchen Sie es mit einem anderen Browser.'
  }
  return errorMessage
}

function isInvalidSessionError(error) {
  return error.code === Parse.Error.INVALID_SESSION_TOKEN
}

function isChunkMissingError(error) {
  return error instanceof SyntaxError ||
    (error.message?.includes?.('SyntaxError:') && error.message?.includes?.("'<'")) ||
    /Loading chunk \S+ failed/.test(error.message || '')
}

const PARSE_FORM_ERROR_CODES = [
  Parse.Error.USERNAME_TAKEN,
  Parse.Error.EMAIL_TAKEN,
  Parse.Error.EMAIL_NOT_FOUND,
  Parse.Error.OBJECT_NOT_FOUND, // includes username/password invalid
  Parse.Error.INVALID_LINK,
  Parse.Error.INVALID_EMAIL_ADDRESS,
  Parse.Error.USERNAME_MISSING,
  Parse.Error.PASSWORD_MISSING,
  Parse.Error.EMAIL_MISSING
]

// Error handling function, return true to indicate the error has been handled
export const handleError = function(error, extras) {
  if (!error) { return true }
  if (isInvalidSessionError(error)) {
    window.localStorage.clear()
    window.location.reload()
    return true
  }
  if (isChunkMissingError(error)) {
    window.location.reload()
    return true
  }
  // Ignore vue-router navigation errors (NavigationFailureType: { redirected: 2, aborted: 4, cancelled: 8, duplicated: 16 })
  if (isNavigationFailure(error)) { return true }

  //  Normalize and dispatch snackbar/error action with the error message
  if (extras.snack) {
    const errorMessage = normalizeErrorMessage(error)
    errorMessage && store.dispatch('snackbar/error', errorMessage)
  }

  // Don't propagate FormErrors further
  if (error instanceof FormError) { return true }
  if (error instanceof Parse.Error && PARSE_FORM_ERROR_CODES.includes(error.code)) { return true }

  // Log error to Sentry in production and staging
  if (process.env.VUE_APP_MODE !== 'development' && error instanceof Error) {
    Sentry.withScope((scope) => {
      extras && scope.setExtras(extras)
      Sentry.captureException(error)
    })
  } else {
    // eslint-disable-next-line no-console
    console.error(error)
    window.$error = error
  }
  return true
}

// Global Vue error helper
Vue.prototype.$error = error => handleError(error, { snack: true, handler: '$error' })

// Vue.config.errorHandler: For Vue.js runtime errors. Whenever an error occurs during the rendering of a Vue component, or during the lifecycle hooks of a Vue component, and it is not caught by a try...catch block within the component or a promise catch block, this global error handler is invoked. It allows you to catch and handle errors that occur during Vue component rendering or processing.
Vue.config.errorHandler = (error, vm, info = {}) => handleError(error, { snack: true, handler: 'vue.config', info })

// error event: This event is triggered when an error occurs in the global scope, such as syntax errors in scripts, failed network requests, or runtime errors that are not caught by try-catch blocks.
window.addEventListener('error', (event) => {
  if (event.target.tagName === 'SCRIPT' && event.error && event.error.message.includes('Failed to find a valid digest in the "integrity" attribute')) {
    Sentry.captureMessage('SRI error detected. Reloading the page...')
    return setTimeout(window.location.reload, 1000)
  }
  return handleError(event.error, { snack: true, handler: 'window' })
})

// unhandledrejection event: This event is triggered when a promise is rejected, but there is no corresponding .catch() handler to handle the rejection. This typically occurs when a promise is rejected but no .catch() or .then() with a second argument (for handling rejections) is attached to the promise chain.
window.addEventListener('unhandledrejection', ({ reason: error }) => handleError(error, { snack: true, handler: 'unhandled' }))
