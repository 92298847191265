import Vue from 'vue'
import { upperFirst, startCase, intersection } from 'lodash'
import { formatDate } from './dates'
import { price } from './numbers'
import store from '@/store'

const VOUCHER_STATUSES = {
  draft: 'Entwurf',
  open: 'Offen',
  paid: 'Bezahlt', // invoices
  paidoff: 'Bezahlt', // credit notes
  voided: 'Storniert'
}

export const PERMISSIONS = [
  {
    value: 'scout',
    label: 'Kann Scouten',
    description: 'Scouts can be assigned to Abfahrtsliste assigned to them and they can use the scout app.',
    accTypes: ['admin', 'intern', 'partner']
  },
  {
    value: 'manage-scouts',
    label: 'Scout Manager',
    description: 'Can see the Abfahrtsliste appointed to them and select and assign scouts.\n They can approve or reject scout submissions.',
    accTypes: ['admin', 'intern', 'partner']
  },
  {
    value: 'manage-fieldwork',
    label: 'Dienstleistungen Manager',
    description: 'Can plan Briefings, Controls and other Dienstleistungen and can appoint Scout Managers.',
    accTypes: ['admin', 'intern']
  },
  {
    value: 'manage-bookings',
    label: 'Buchung Manager',
    description: 'Internal booking managers can see and approve booking requests.\nPartners can see bookings and make booking requests.',
    accTypes: ['admin', 'intern', 'partner']
  },
  {
    value: 'manage-frames',
    label: 'Rahmen Manager',
    description: 'Internal rahmen managers can view and update Moskitorahmen and approve requests.\nPartners can see their Moskitorahmen locations and send requests.',
    accTypes: ['admin', 'intern', 'partner'],
    filter: form => !form.companyId || form.companyId === '19me3Ge8LZ'
  },
  {
    value: 'sales',
    label: 'Vertrieb',
    description: 'Can manage leads and offers.',
    accTypes: ['admin', 'intern']
  }
]

const CUBE_COLORS = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: 'warning', // special-format
  5: 'warning', // frame-mount
  6: 'accent', // booked
  7: '',
  8: '',
  9: ''
}

export const SELECTION_RATINGS = {
  '🟢': 'Qualitativ',
  '🟠': 'in Klärung',
  '🔴': 'nicht Qualitativ',
  '⚪': 'nicht Selektiert'
}

const ORDER_COLORS = {
  '-1': 'error',
  0: 'white',
  1: 'yellow',
  2: 'white',
  2.1: '#ebebeb',
  3: 'success',
  4: '#c3c3c3',
  5: '#c3c3c3'
}

const INVOICE_COLORS = {
  0: '',
  1: '',
  2: 'success',
  3: 'error',
  4: 'secondary'
}

const CLASS_NAMES = {
  _User: 'Benutzer',
  Offer: 'Angebot',
  Contract: 'Vertrag',
  Booking: 'Buchung',
  SpecialFormat: 'Sonderformat',
  FrameMount: 'Moskitorahmen',
  Invoice: 'Rechnung',
  CreditNote: 'Gutschrift',
  Company: 'Unternehmen',
  Briefing: 'Briefing',
  Control: 'Kontrolle',
  Assembly: 'Montage',
  Disassembly: 'Demontage',
  CustomService: 'Diensleistung',
  TaskList: 'Abfahrtsliste'
}

const CLASS_NAMES_NOMINATIV = {
  _User: 'der',
  Angebot: 'das',
  Contract: 'der',
  Booking: 'die',
  SpecialFormat: 'das',
  FrameMount: 'der',
  Invoice: 'die',
  CreditNote: 'die',
  Company: 'das',
  Briefing: 'das',
  Control: 'die',
  Assembly: 'die',
  Disassembly: 'die',
  CustomService: 'die',
  TaskList: 'die'
}

function makeAkkusativ(article) {
  return {
    der: 'den',
    die: 'die',
    das: 'das'
  }[article]
}

function makeDativ(article) {
  return {
    der: 'dem',
    die: 'der',
    das: 'dem'
  }[article]
}

const CLASS_NAMES_PLURAL = {
  _User: 'Benutzer',
  Offer: 'Angebote',
  Contract: 'Verträge',
  Booking: 'Buchungen',
  SpecialFormat: 'Sonderformate',
  FrameMount: 'Moskitorahmen',
  Invoice: 'Rechnungen',
  CreditNote: 'Gutschriften',
  Company: 'Unternehmen',
  Briefing: 'Briefings',
  Control: 'Kontrolle',
  Assembly: 'Montagen',
  Disassembly: 'Demontagen',
  CustomService: 'Dienstleistungen',
  TaskList: 'Abfahrtsliste'
}

const CLASS_NAMES_TO = {
  _User: 'zum Benutzer',
  Offer: 'zum Angebot',
  Contract: 'zu Vertrag',
  Booking: 'zur Buchung',
  SpecialFormat: 'zum Sonderformat',
  FrameMount: 'zum Moskitorahmen',
  Invoice: 'zur Rechnung',
  CreditNote: 'zur Gutschrift',
  Company: 'zum Unternehmen',
  Briefing: 'zum Briefing',
  Control: 'zur Kontrolle',
  Assembly: 'zur Montage',
  Disassembly: 'zur Demontage',
  CustomService: 'zur Diensleistung',
  TaskList: 'zur Abfahrtsliste'
}

const CLASS_NAMES_FROM = {
  _User: 'vom Benutzer',
  Offer: 'dem Angebot',
  Contract: 'den Vertrag',
  Booking: 'der Buchung',
  SpecialFormat: 'dem Sonderformat',
  FrameMount: 'dem Moskitorahmen',
  Invoice: 'der Rechnung',
  CreditNote: 'der Gutschrift',
  Company: 'dem Unternehmen',
  Briefing: 'dem Briefing',
  Control: 'der Kontrolle',
  Assembly: 'der Montage',
  Disassembly: 'der Demontage',
  CustomService: 'der Diensleistung',
  TaskList: 'der Abfahrtsliste'
}

const CLASS_NAMES_DIESE = {
  _User: 'dieser Benutzer',
  Offer: 'dieses Angebot',
  Contract: 'dieser Vertrag',
  Booking: 'dieser Buchung',
  SpecialFormat: 'dieser Sonderformat',
  FrameMount: 'dieser Moskitorahmen',
  Invoice: 'diese Rechnung',
  CreditNote: 'diese Gutschrift',
  Company: 'dieses Unternehmen',
  Briefing: 'diesem Briefing',
  Control: 'dieser Kontrolle',
  Disassembly: 'dieser  Demontage',
  CustomService: 'dieser Diensleistung',
  Assembly: 'dieser Montage',
  TaskList: 'diese Abfahrtsliste'
}

Vue.filter('mediaColor', (media) => {
  if (media === 'MFG') {
    return '#9bc6ff'
  }
  return '#ffb2b2'
})

const durationDisplay = ({ initialDuration, extendedDuration }) => {
  if (!initialDuration) {
    return '-'
  }
  let text = initialDuration
  if (extendedDuration) {
    text += ' + ' + extendedDuration
  }
  text += ' Monate'
  return text
}
Vue.filter('durationDisplay', durationDisplay)

const extensionIcon = ({ canceledAt, autoExtendsAt }) => {
  if (canceledAt) {
    return '❌'
  }
  if (autoExtendsAt) {
    return '➡️'
  }
  return '⏹️'
}
Vue.filter('extensionIcon', extensionIcon)

const extensionDescription = ({ canceledAt, autoExtendsAt, autoExtendsBy }) => {
  if (canceledAt) {
    return `Verlängerung am ${formatDate(canceledAt)} gekündigt. Wird nicht automatisch verlängert.`
  }
  if (autoExtendsAt) {
    return `Wird am ${formatDate(autoExtendsAt)} automatisch um ${autoExtendsBy} Monate verlängert.`
  }
  return 'Wird nicht automatisch verlängert.'
}
Vue.filter('extensionDescription', extensionDescription)

Vue.filter('orderType', order => CLASS_NAMES[order.className])
Vue.filter('orderColor', order => ORDER_COLORS[order.status || 0])
Vue.filter('orderDuration', (order) => {
  const { initialDuration, extendedDuration, autoExtendsBy, canceledAt } = order
  if (!initialDuration) {
    return '-'
  }
  let text = initialDuration
  if (extendedDuration) {
    text += ' + ' + extendedDuration
  }
  text += ' Monate'
  if (canceledAt) {
    return text + ' ❌'
  }
  if (autoExtendsBy) {
    return text + ' ➡️'
  }
  return text + ' ⏹️'
})
Vue.filter('cubeColor', cube => CUBE_COLORS[cube.s || 0])

// default level is error
const getCubeFlags = flags => intersection(Object.keys(store.state.dictionary.cubeFlags), flags || [])
const getCubeFlagLevel = value => store.state.dictionary.cubeFlags?.[value].level || 'error'
Vue.filter('flagLevel', getCubeFlagLevel)
Vue.filter('flagLabel', value => store.state.dictionary.cubeFlags?.[value].label)
Vue.filter('flagLevelDescription', value => ({ warning: '⚠️ Warnung', error: '⛔ Nicht vermarktbar' }[value]))

Vue.filter('invoiceColor', invoice => INVOICE_COLORS[invoice.status || 0])
Vue.filter('creditNoteColor', creditNote => INVOICE_COLORS[creditNote.status || 0])
Vue.filter('companyColor', (company) => {
  if (company.deletedAt) {
    return 'error'
  }
  // return a color depending on the type
  if (company.distributor) {
    return 'info'
  }
  if (company.agency) {
    return 'blue lighten-2'
  }
  if (company.lessor) {
    return 'pink lighten-2'
  }
  if (company.scoutor) {
    return 'lightgreen'
  }
  return ''
})

Vue.filter('cubeOrderStatusDescription', (order) => {
  const { status, endsAt, className, earlyCanceledAt, canceledAt, autoExtendsAt, autoExtendsBy } = order
  if (status === -1) {
    return getClassName(className) + ' storniert.'
  }
  if (canceledAt) {
    return getClassName(className) + ` gekündigt, CityCube ${endsAt >= store.state.today ? 'aktiv bis' : 'ausgelaufen am'} ${formatDate(endsAt)}. ❌`
  }
  if (earlyCanceledAt === true) {
    return 'CityCube aus dem Vertrag entfernt. (Storniert) ❌'
  }
  if (earlyCanceledAt) {
    return `CityCube frühzeitig storniert, ${earlyCanceledAt >= store.state.today ? 'aktiv bis' : 'ausgelaufen am'} ${formatDate(earlyCanceledAt)}. ❌`
  }
  if (autoExtendsAt) {
    return `Wird am ${formatDate(autoExtendsAt)} automatisch um ${autoExtendsBy} Monate verlängert. ➡️`
  }
  if (endsAt) {
    return endsAt >= store.state.today ? 'Wird nicht automatisch verlängert. ⏹️' : `Ausgelaufen am ${formatDate(endsAt)}`
  }
  return '-'
})

Vue.filter('cubeOrderStatusIcon', order => (order.earlyCanceledAt || order.canceledAt)
  ? '❌'
  : order.autoExtendsAt ? '➡️' : '⏹️'
)

Vue.filter('companyIcon', (company) => {
  if (company.deletedAt) {
    return 'delete'
  }
  if (company.distributor) {
    return 'domain'
  }
  if (company.agency) {
    return 'handshake-outline'
  }
  if (company.lessor) {
    return 'bank'
  }
  if (company.scoutor) {
    return 'run-fast'
  }
  return 'store'
})

export const userName = (userOrId, fallback = '') => store.state.users?.[userOrId?.objectId || userOrId]?.fullname || fallback
Vue.filter('userName', userName)
export const userColor = userId => store.state.users?.[userId]?.color
Vue.filter('userColor', userColor)

export const companyName = companyId => store.state.companies?.[companyId]?.name
Vue.filter('companyName', companyName)
Vue.filter('countryName', val => store.state.dictionary?.countries?.[val] || '')

const getStateName = state => store.state.dictionary.states?.[state?.objectId || state?.id || state]?.name
Vue.filter('stateName', getStateName)

export const getClassName = (className, variant) => {
  className = upperFirst(className)
  if (variant === true) {
    return CLASS_NAMES_PLURAL[className] || CLASS_NAMES[className] || className
  }
  const nominativ = CLASS_NAMES_NOMINATIV[className]
  const classDisplay = CLASS_NAMES[className] || className
  if (variant === 'nominativ') {
    return nominativ + ' ' + classDisplay
  }
  if (variant === 'akkusativ') {
    return makeAkkusativ(nominativ) + ' ' + classDisplay
  }
  if (variant === 'dativ') {
    return makeDativ(nominativ) + ' ' + classDisplay
  }
  if (variant === 'to') {
    return CLASS_NAMES_TO[className] || className
  }
  if (variant === 'from') {
    return CLASS_NAMES_FROM[className] || className
  }
  if (variant === 'diese') {
    return CLASS_NAMES_DIESE[className] || className
  }
  return CLASS_NAMES[className] || className
}

const SIDE_FACES = { left: 'Linke Seite', right: 'Rechte Seite' }
const getPrintPackageFaceName = face => store.state.dictionary.printPackageFaces?.[face] || SIDE_FACES[face]
Vue.filter('printPackageFaceName', getPrintPackageFaceName)
export const facesDescription = (faces, withCount = true) => {
  const texts = []
  const hasTop = Boolean(faces.top)
  for (const face of Object.keys(faces || {}).filter(face => face !== 'top')) {
    let faceName = getPrintPackageFaceName(face)
    if (withCount) {
      faceName = `${faces[face]}x ${faceName}`
    }
    if (!withCount && face === 'side') {
      if (faces[face] === 1) {
        faceName = 'Eine ' + faceName
      }
      if (faces[face] === 2) {
        faceName = 'Beide ' + faceName
      }
    }
    if (face === 'front' && hasTop) {
      faceName += ' + Deckel'
    }
    texts.push(faceName)
  }
  return texts.join(' | ')
}
export const housingTypeCode = ht => store.state.dictionary.housingTypes?.[ht?.objectId || ht?.id || ht]?.code
Vue.filter('facesDescription', facesDescription)
const printPackageTypeName = type => store.state.dictionary.printPackageTypes?.[type]
Vue.filter('printPackageTypeName', printPackageTypeName)

export const sideSelectionDisabled = (side, htId) => {
  if (!htId) { return false }
  const housingType = store.getters.housingType(htId)
  if (!housingType) { return false }
  if (side === 'front') { return false }
  return housingType.faces[side] === false
}

Vue.filter('housingTypeCode', housingTypeCode)
Vue.filter('leadStatus', status => store.state.dictionary.leadStatuses?.[status || 0])
Vue.filter('offerStatus', status => store.state.dictionary.offerStatuses?.[status || 0])
Vue.filter('orderStatus', status => store.state.dictionary.orderStatuses?.[status || 0])
Vue.filter('invoiceStatus', status => store.state.dictionary.invoiceStatuses?.[status || 0])
Vue.filter('creditNoteStatus', status => store.state.dictionary.creditNoteStatuses?.[status || 0])
Vue.filter('paymentType', type => store.state.dictionary.paymentTypes?.[type || 0])
Vue.filter('cubeStatus', ({ s, fm }) => {
  const status = store.state.dictionary.cubeStatuses?.[s || 0]
  if (s === 5) {
    return fm?.qty ? `${status} (x${fm?.qty})` : 'Reserviert für Moskitorahmen'
  }
  return status
})
export const accType = val => store.state.dictionary.accTypes?.[val]
Vue.filter('accType', accType)
export const userPermissions = (permissions = []) => {
  return permissions
    .map(value => PERMISSIONS.find(x => x.value === value)?.label || value)
    .join(', ')
}
Vue.filter('userPermissions', userPermissions)
Vue.filter('voucherStatus', status => VOUCHER_STATUSES[status] || status)
Vue.filter('className', getClassName)
Vue.filter('startCase', startCase)

const cubeAddress = (value, includeState = false) => {
  if (!value) {
    return ''
  }
  const { str, hsnr, plz, ort, state, stateId } = value
  const address = [str, (hsnr || '') + ',', plz, ort].join(' ')
  return includeState
    ? address + ' ' + getStateName(state || stateId)
    : address
}
Vue.filter('cubeAddress', cubeAddress)

Vue.filter('cubeSize', (val) => {
  return {
    KVZ: 'Regular Size',
    MFG: 'Premium Size',
    0: 'Unbekannt'
  }[val || 0]
})

Vue.filter('nominatimAddress', nominatim => nominatim?.address
  ? [
      ['road', 'house_number'].map(key => nominatim.address[key]).join(' ') + ',',
      ['postcode', 'town', 'city', 'state'].map(key => nominatim.address[key]).filter(Boolean).join(' ')
    ].join(' ')
  : (nominatim?.disabled ? 'Im Testmodus nicht verfügbar' : 'Fehler bei der Adressauflösung')
)
Vue.filter('cubeDirectionsLink', ({ objectId, gp }) => gp && objectId
  ? `https://maps.apple.com/?ll=${gp.latitude},${gp.longitude}&q=${objectId}`
  : ''
)

Vue.filter('housingType', (cube) => {
  const htId = cube.ht?.objectId || cube.ht?.id || cube.htId
  if (htId) {
    return store.state.dictionary.housingTypes?.[htId]?.code
  }
  if (cube.media) {
    return cube.media + ' - Unbekannt'
  }
  return 'Gehäusetyp Unbekannt'
})

export const BOOKING_REQUEST_STATUS_COLORS = {
  0: 'info',
  1: 'success',
  2: 'error'
}
export const FRAME_MOUNT_STATUS_COLORS = {
  draft: 'white',
  pending: 'info',
  accepted: 'success',
  rejected: 'error'
}
export const LEAD_STATUS_COLORS = {
  0: 'success',
  1: 'info',
  2: 'dortmund',
  3: '#c3c3c3'
}
export const LEAD_STATUS_ICONS = {
  0: 'mdi-calendar-plus',
  1: 'mdi-calendar-clock',
  2: 'mdi-send-check',
  3: 'mdi-calendar-remove-outline'
}
Vue.filter('leadStatusColor', status => LEAD_STATUS_COLORS[status || 0])
Vue.filter('leadStatusIcon', status => LEAD_STATUS_ICONS[status || 0])

Vue.filter('bookingRequestType', ({ type } = {}) => store.state.dictionary.bookingRequestTypes?.[type] || type || '')
Vue.filter('bookingRequestStatus', ({ status } = {}) => store.state.dictionary.bookingRequestStatuses?.[status || 0])
Vue.filter('bookingRequestStatusColor', ({ status } = {}) => BOOKING_REQUEST_STATUS_COLORS[status || 0])
Vue.filter('frameMountRequestStatus', status => store.state.dictionary.frameMountRequestStatuses?.[status || 0])
Vue.filter('frameMountRequestStatusColor', status => FRAME_MOUNT_STATUS_COLORS[status || 0])

Vue.mixin({
  methods: {
    userName,
    userColor,
    getCubeFlags,
    getCubeFlagLevel,
    cubeAddress,
    getClassName,
    getStateName,
    getHousingTypeCode: housingTypeCode
  }
})

Vue.filter('contractPricingModel', (model) => {
  return {
    0: 'Manuelle Eintragung',
    gradual: 'Staffelkonditionen',
    fixed: 'Vereinbarte Konditionen',
    zero: '0€ (Fullservicepreis/Geschenke)'
  }[model || 0]
})

Vue.filter('distributorConditions', (distributor) => {
  if (!distributor) { return '' }
  if (distributor.pricingModel === 'commission') {
    return `${distributor.commission}% Provision von Endkundepreisen`
  }
  // if (distributor.pricingModel === 'fixed') {
  //   return 'Festpreise pro CityCube'
  // }
  if (!distributor.pricingModel) {
    return 'Individueller Preis pro CityCube'
  }
})

export const billingCycle = x => store.state.dictionary.billingCycles?.[x]
Vue.filter('billingCycle', billingCycle)

export const productionBilling = (v, total) => {
  total = total
    ? `Der Gesamtbetrag von <b>${price(total)}</b>`
    : 'Der Gesamtbetrag'
  switch (v) {
    case 0:
      return 'Wird nicht abgerechnet.'
    case 1:
      return `${total} wird mit der ersten Rechnung abgerechnet.`
    case 12:
      return `${total} wird in monatliche Raten auf die Medienrechnungen für das erste Jahr aufgeteilt.`
    case 24:
      return `${total} wird in monatliche Raten auf die Medienrechnungen der ersten 2 Jahre aufgeteilt.`
    case 36:
      return `${total} wird in monatliche Raten auf die Medienrechnungen der ersten 3 Jahre aufgeteilt.`
  }
  return 'Wird nicht abgerechnet'
}
Vue.filter('productionBilling', productionBilling)

export const pkDisplay = (pk, expandState) => {
  const [stateId, ort] = pk.split(':')
  const state = (expandState && store.state.dictionary.states[stateId]?.name) || stateId
  return [ort, `(${state})`].join(' ')
}
Vue.filter('pkDisplay', pkDisplay)

Vue.mixin({
  methods: {
    productionBilling
  }
})
