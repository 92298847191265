/* eslint-disable no-console */
import { register } from 'register-service-worker'
// Sentry does not need to be initialized here as dsn and environment are derived from environment variables
import * as Sentry from '@sentry/browser'

async function clearCachesOfPrePwaVersions() {
  try {
    if (localStorage.getItem('prePwaCachesCleaned')) { return }

    const cacheNames = await caches.keys()
    await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)))

    localStorage.setItem('prePwaCachesCleaned', true)

    Sentry.captureMessage('Caches of pre-PWA versions have been cleaned.')

    location.reload()
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTag('function', 'clearCachesOfPrePwaVersions')
      Sentry.captureException(error)
    })
  }
}

async function registerServiceWorker() {
  await clearCachesOfPrePwaVersions()
  try {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log('Service worker is active.')
      },
      registered(registration) {
        console.log('Service worker has been registered.')

        // Routinely check for app updates by testing for a new service worker.
        const intervalId = setInterval(() => {
          if (registration) {
            registration.update()
          } else {
            clearInterval(intervalId)
          }
        }, 1000 * 60 * 5) // Check every five minutes
      },
      cached() {
        console.log('Content has been cached for offline use.')
      },
      updatefound() {
        console.log('New content is downloading.')
      },
      updated(registration) {
        console.log('New content is available please refresh.')
        // Dispatch a custom event to notify the app of the update
        document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
      },
      offline() {
        console.log('No internet connection found. App is running in offline mode.')
      },
      error(error) {
        console.error('Error during service worker registration:', error)
        Sentry.withScope((scope) => {
          scope.setTag('function', 'register error listener')
          Sentry.captureException(error)
        })
      }
    })
  } catch (error) {
    console.error('Error in registerServiceWorker:', error)
    Sentry.withScope((scope) => {
      scope.setTag('function', 'registerServiceWorker')
      Sentry.captureException(error)
    })
  }
}

process.env.NODE_ENV === 'production' && registerServiceWorker()
