import Vue from 'vue'
import { FormError } from '@/errors'
import './numbers'
import './dates'
import './files'
import './template-tags'
import './fieldwork'

export const capitalizeFirstLetter = value => value
  ? value.charAt(0).toUpperCase() + value.slice(1)
  : ''

const fullName = ({ firstName, lastName, prefix }, reverse, wPrefix) => {
  const list = [firstName, lastName].filter(Boolean)
  wPrefix && prefix && list.unshift(prefix)
  reverse && list.reverse()
  return list.join(' ')
}

const initials = (value) => {
  if (!value) {
    return ''
  }
  const words = value.split(' ').map(w => w[0])
  if (words.length > 2) {
    return words[0] + words.reverse()[0]
  }
  return words.join('')
}

const geopoint = (geopoint, separator = ', ') => {
  if (!geopoint) {
    return ''
  }
  return geopoint.latitude + separator + geopoint.longitude
}

function dictToArr(dict, value = 'value', label = 'label') {
  const items = []
  for (const key of Object.keys(dict || {})) {
    const item = {}
    item[value] = key
    item[label] = dict[key]
    items.push(item)
  }
  return items
}

function gridStyles(columns) {
  const gridTemplateColumns = columns
    .filter(c => !c.hide)
    .map(({ minWidth, maxWidth, expand }) => {
      const min = minWidth || 'auto'
      const max = maxWidth || 'auto'
      return expand ? '1fr' : `minmax(${min}, ${max})`
    }).join(' ')
  return { gridTemplateColumns }
}

Vue.mixin({
  filters: {
    fullName,
    initials,
    capitalizeFirstLetter,
    geopoint,
    dictToArr,
    gridStyles
  },
  computed: {
    $today() {
      return this.$store.state.today
    },
    breakpoint() {
      return this.$vuetify.breakpoint
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile
    },
    iOSVersion() {
      const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/)
      return match ? [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || 0, 10)] : null
    }
  },
  methods: {
    fullName,
    capitalizeFirstLetter,
    geopoint,
    dictToArr,
    async $validate(formRef = 'form', extraErrors = []) {
      const form = this.$refs[formRef]
      const valid = await form.validate()
      if (!valid || extraErrors?.length) {
        const message = [extraErrors, Object.values(form.errors).filter(err => err.length).map(err => err[0])].flat().join(', ')
        throw new FormError(message)
      }
    },
    async $clip(value, what = 'Link') {
      if (!window.isSecureContext) {
        return this.$warn('Die Zwischenablage kann nur in sicheren Umgebungen verwendet werden.')
      }
      try {
        await navigator.clipboard.writeText(value)
        this.$success(what + ' in die Zwischenablage kopiert')
      } catch (error) {
        this.$error(error)
      }
    },
    $reload() {
      return window.location.reload()
    },
    $confirm(message, fn) {
      if (!confirm(message)) {
        return
      }
      fn()
    },
    $success(payload) {
      this.$store.dispatch('snackbar/success', payload?.message || payload)
      return payload?.data
    },
    $warn(message) {
      this.$store.dispatch('snackbar/warn', message)
    },
    $wip() {
      this.$store.dispatch('snackbar/new', { type: 'warning', message: 'Diese Funktion ist noch in der Entwicklung.' })
    },
    $exportUrl(uri) {
      const url = new URL(`${process.env.VUE_APP_EXPORTS_URL}/${uri}`)
      const user = this.$Parse.User.current()
      user && url.searchParams.append('sid', user.getSessionToken())
      return url.toString()
    },
    isSameParseObj(value, compare) {
      if (!value || !compare) {
        return false
      }
      const aId = typeof value === 'string'
        ? value
        : value?.id || value?.objectId
      const bId = typeof compare === 'string'
        ? compare
        : compare?.id || compare?.objectId
      return aId === bId
    }
  }
})

export const progressColor = (progress) => {
  if (progress < 25) { return 'red' }
  if (progress < 50) { return 'orange' }
  if (progress < 75) { return 'yellow' }
  return 'success'
}
