export const state = () => ({
  hash: null
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  SET_PAGE(state, pageId) {
    state.hash = pageId ? '#page=' + pageId : null
  }
}

// actions
export const actions = {
  open({ commit }, pageId) {
    commit('SET_PAGE', pageId)
  },
  close({ commit }) {
    commit('SET_PAGE', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
