// http://localhost:8080/n/IyF7E0oP8D
import Parse from '@/plugins/parse'

export default async(to, from, next) => {
  try {
    const { app, route } = await Parse.Cloud.run('notification-read', { id: to.params.nId })
    if (app === 'scout' && window.localStorage.getItem('app') !== 'scout') {
      window.localStorage.setItem('app', 'scout')
      window.location.href = to.fullPath
      return
    }
    if (app !== 'scout' && window.localStorage.getItem('app') === 'scout') {
      window.localStorage.removeItem('app')
      window.location.href = to.fullPath
      return
    }
    return next(route)
  } catch (error) {
    if (from.name) { return next(false) }
    try {
      return next({ name: 'index' })
    } catch (error) {
      window.location.href = '/'
    }
  }
}
