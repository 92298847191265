var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('router-view', {
    on: {
      "hook:mounted": _vm.unload
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };