import Vue from 'vue'
import { round } from 'lodash'
import moment from 'moment'
moment.locale('de')

Vue.filter('formatUnixDate', (value, format) => {
  return moment.unix(value).format(format)
})
export const formatDate = (value, format = 'DD.MM.YYYY') => {
  if (!value) {
    return null
  }
  if (typeof value === 'number' && `${value}`.length === 10) {
    value = value * 1000
  }
  return moment(value).format(format)
}

const timestamp = (value) => {
  if (!value) {
    return null
  }
  if (typeof value === 'number' && `${value}`.length === 10) {
    value = value * 1000
  }
  return (new Date(value)).getTime()
}

const calendar = (value, today = false) => value
  ? moment(value).calendar({
    sameDay: today ? '[heute um] HH:mm' : 'HH:mm',
    nextDay: '[morgen um] HH:mm',
    nextWeek: 'dddd DD.MM.YYYY [um] HH:mm',
    lastDay: '[gestern um] HH:mm',
    lastWeek: 'dddd DD.MM.YYYY [um] HH:mm',
    sameElse: 'dddd DD.MM.YYYY [um] HH:mm'
  })
  : ''

Vue.filter('formatDate', formatDate)
Vue.filter('timestamp', timestamp)
Vue.filter('formatDatetime', (value, sep) => formatDate(value, sep ? `DD.MM.YYYY [${sep}] HH:mm` : 'DD.MM.YYYY HH:mm'))
Vue.filter('calendar', calendar)
Vue.filter('fromNow', val => val ? moment(val).fromNow() : 'nie')

Vue.filter('orIfEarlier', (val, compare) => moment(compare).isBefore(val) ? compare : val)
Vue.filter('orIfLater', (val, compare) => moment(compare).isAfter(val) ? compare : val)

Vue.filter('formatDuration', (value) => {
  if (!value) {
    return null
  }
  if (`${value}`.length === 10) {
    value = value * 1000
  }
  return moment.duration(value).humanize()
})

Vue.filter('period', ({ periodStart, periodEnd, quarter }) => {
  if (quarter) { return quarter }
  return [formatDate(periodStart), formatDate(periodEnd)].join('-')
})

const getPeriodTotal = function(periodStart, periodEnd, monthlyTotal) {
  const carry = moment(periodStart)
  periodEnd = moment(periodEnd)
  const eachMonth = {}
  while (true) {
    const endOfMonth = carry.clone().endOf('month')
    const end = periodEnd.isBefore(endOfMonth) ? periodEnd : endOfMonth
    const days = end.diff(carry, 'days') + 1
    eachMonth[carry.format('MM-YYYY')] = round(days / carry.daysInMonth(), 5)
    if (end.isSame(periodEnd, 'month')) { break }
    carry.add(1, 'month').set('date', 1)
  }
  const months = Object.values(eachMonth).reduce((acc, val) => round(acc + val, 5), 0)
  return { months, total: round(months * monthlyTotal, 2) }
}

Vue.mixin({
  methods: {
    formatDate,
    timestamp,
    calendar,
    getPeriodTotal
  }
})
