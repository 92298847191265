import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import de from 'vee-validate/dist/locale/de.json'
import * as rules from 'vee-validate/dist/rules'
import moment from 'moment'
import { unpackDownloadURL } from './../utils/helpers'
/* eslint import/namespace: ['error', { allowComputed: true }] */
for (const key of Object.keys(rules)) {
  extend(key, rules[key])
}
localize('de', de)

extend('url', {
  validate: value => value.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g) !== null,
  message: 'The {_field_} must be a valid url'
})

extend('arrLength', {
  validate: (value, { min, max }) => Array.isArray(value) && (!min || value.length >= min) && (!max || value.max <= max),
  message: (fieldName, { _value_, min, max }) => {
    if (!Array.isArray(_value_)) {
      return `The ${fieldName} list must be an array`
    }
    if (min && _value_.length < min) {
      return `The ${fieldName} list must at have at least ${min} elements`
    }
    if (max && _value_.length > max) {
      return `The ${fieldName} list can at most have ${max} elements`
    }
    // LOG: validation arrLength
    return `The ${fieldName} doesn't pass the arrLength rule.`
  },
  params: ['min', 'max']
})

extend('extensions', {
  validate(value, { oneOf }) {
    const { ext } = unpackDownloadURL(value._url)
    return oneOf.map(x => x.toLowerCase()).includes(ext.toLowerCase())
  },
  message: (fieldName, { oneOf }) => {
    return `The ${fieldName} must have of one of the following file extensions: ${oneOf.join(', ')}`
  },
  params: ['oneOf']
})

extend('dateformat', {
  validate(value, { format }) {
    return value.length === format.length && moment(value, format).isValid()
  },
  message: (fieldName, { format }) => {
    return `${fieldName} muss im folgenden Format sein: ${format.replace('DD.MM.YYYY', 'TT.MM.JJJJ').replace('HH:mm', 'SS:MM')}`
  },
  params: ['format']
})

extend('minDate', {
  validate(value, { dateformat, min }) {
    const date = moment(value, dateformat)
    return date.isSameOrAfter(moment(min))
  },
  message(fieldName, { dateformat, min }) {
    return `${fieldName} darf nicht weniger als ${moment(min).format(dateformat)} sein.`
  },
  params: ['dateformat', 'min']
})

extend('maxDate', {
  validate(value, { dateformat, max }) {
    const date = moment(value, dateformat)
    return date.isSameOrBefore(moment(max))
  },
  message(fieldName, { dateformat, max }) {
    return `${fieldName} darf nicht größer als ${moment(max).format(dateformat)} sein.`
  },
  params: ['dateformat', 'max']
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
