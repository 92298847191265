import Vue from 'vue'
/* eslint-disable-next-line import/no-named-as-default */
import VCurrencyField from 'v-currency-field'

import { VTextField } from 'vuetify/lib'
Vue.component('VTextField', VTextField)

Vue.use(VCurrencyField, {
  locale: 'de-DE',
  currency: 'EUR',
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})
