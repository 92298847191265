export const state = () => ({
  selected: null,
  onUpdate: null
})

export const mutations = {
  SELECT_CUBE(state, { id, onUpdate }) {
    state.selected = id
    state.onUpdate = onUpdate
  }
}

export const actions = {
  select({ commit }, { id, onUpdate }) {
    commit('SELECT_CUBE', { id, onUpdate })
  },
  discard({ commit }) {
    commit('SELECT_CUBE', {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
