import Vue from 'vue'
import Parse from 'parse'
import { isPlainObject, isArray } from 'lodash'

const serverURL = process.env.VUE_APP_SERVER_URL
Parse.initialize(process.env.VUE_APP_APP_ID, process.env.VUE_APP_JS_KEY)
Parse.serverURL = serverURL

export const normalize = function(value) {
  if (!value) {
    return value
  }
  if (value.toJSON && !(value instanceof Date)) {
    const json = value.toJSON()
    json.className = value.className
    for (const key of Object.keys(json)) {
      json[key] = normalize(json[key])
    }
    return json
  }
  if (isPlainObject(value)) {
    if ('__type' in value) {
      if (value.__type === 'Date') {
        return value.iso
      }
      if (value.__type === 'Pointer') {
        if (value.attributes) {
          const json = {}
          for (const key of Object.keys(value.attributes)) {
            json[key] = normalize(value.attributes[key])
          }
          return json
        }
      }
    }
    for (const key of Object.keys(value)) {
      value[key] = normalize(value[key])
    }
    return value
  }
  if (isArray(value)) {
    return value.map(normalize)
  }
  return value
}

const getParseObject = function({ className, objectId }) {
  const Item = Parse.Object.extend(className)
  const item = new Item()
  item.id = objectId
  return item
}

export const parsify = (className, objectId) => getParseObject({ className, objectId })

Vue.prototype.$Parse = Parse
Vue.prototype.$normalize = normalize
Vue.prototype.$parsify = parsify
Vue.prototype.$user = () => Parse.User.current()
Vue.prototype.$run = (...args) => Parse.Cloud.run(...args)
Vue.prototype.$geopoint = (...args) => new Parse.GeoPoint(...args)
Vue.prototype.$query = function(className, limit, skip, withCount) {
  const query = new Parse.Query(className)
  limit && query.limit(limit)
  skip && query.skip(skip)
  withCount && query.withCount()
  return query
}
global.$attr = (object, key) => typeof object.get === 'function' ? object.get(key) : object[key]

Vue.prototype.$new = function(className, attrs) {
  const Obj = Parse.Object.extend(className)
  return new Obj(...attrs)
}

export default Parse
