import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import posthog from './posthog'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_MODE,
  integrations: [
    Sentry.browserTracingIntegration(),
    new posthog.SentryIntegration(
      posthog,
      'isokosan',
      4506943583289344,
      undefined,
      ['error', 'info']
    )
  ],
  tracesSampleRate: process.env.VUE_APP_MODE === 'production' ? 0.05 : 0,
  tracePropagationTargets: [process.env.VUE_APP_SERVER_URL]
})

export default Sentry
