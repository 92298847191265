export default () => [
  {
    path: '/',
    component: () => import('./layouts/default.vue'),
    meta: { middleware: ['public'] },
    children: [
      {
        path: '',
        name: 'index',
        component: () => import('./pages/index.vue')
      },
      {
        path: '/o/:id',
        name: 'offer',
        component: () => import('./pages/offer.vue')
      },
      {
        path: '/op/:id',
        name: 'offer-print',
        component: () => import('./pages/offer-print.vue')
      },
      {
        path: '/ar/:id',
        name: 'assembly-report',
        component: () => import('./pages/assembly-report.vue')
      }
    ]
  },
  { path: '*', redirect: '/' }
]
