import Vue from 'vue'
import store from '@/store'

export const SCOUT_QUESTIONS = [
  {
    title: 'Sichbarkeit',
    name: 'obstructionLevel',
    items: [
      { value: 'unobscured', label: 'Dauerhaft sichtbar' },
      { value: 'partly', label: 'Eingeschränkt sichtbar' },
      { value: 'concealed', label: 'Schlecht sichtbar' }
    ],
    required: true
  },
  {
    title: 'Schaltkasten steht in Ampelnähe?',
    name: 'nearTrafficLights',
    items: [
      { value: 'y', label: 'Steht in Ampelnähe (max. 20 Meter; Wartebereich der Ampel)' },
      { value: 'n', label: 'Steht nicht in Ampelnähe' }
    ],
    required: true
  },
  {
    title: 'Position zur Verkehrsachse',
    name: 'angleToTraffic',
    items: [
      { value: 'perpendicular', label: 'Quer zur Fahrbahn' },
      { value: 'diagonal', label: 'Leicht quer zur Fahrbahn' },
      { value: 'parallel', label: 'Parallel zur Fahrbahn' }
    ],
    required: true
  }
]

// Sichtbarkeit
export const OBSTRUCTION_LEVELS = {
  unobscured: 'Dauerhaft sichtbar',
  partly: 'Eingeschränkt sichtbar',
  concealed: 'Schlecht sichtbar'
}
// Position zur Verkersachse
export const ANGLES_TO_TRAFFIC = {
  perpendicular: 'Quer zur Fahrbahn',
  diagonal: 'Leicht quer zur Fahrbahn',
  parallel: 'Parallel zur Fahrbahn'
}
// Ampelnähe
export const TRAFFIC_LIGHTS = {
  y: 'In Ampelnähe',
  n: 'Nicht in Ampelnähe'
}

export const TASK_LIST_ICONS = {
  scout: 'mdi-binoculars',
  control: 'mdi-note-search',
  assembly: 'mdi-sticker-plus',
  disassembly: 'mdi-sticker-remove',
  'special-format': 'mdi-pipe-wrench',
  'custom-task': 'mdi-pipe-wrench',
  'custom-service': 'mdi-pipe-wrench'
}

export const TASK_LIST_ICON_CODES = {
  scout: 0xF00A5,
  control: 0xF1653,
  assembly: 0xF136C,
  disassembly: 0xF136E,
  'special-format': 0xF1354,
  'custom-task': 0xF1354,
  'custom-service': 0xF1354
}

export const TASK_LIST_COLORS = {
  scout: 'yellow accent-4', // #FFD600
  control: 'cyan darken-4', // #006064
  assembly: 'deep-purple darken-4', // #311B92
  disassembly: 'pink accent-4', // #C51162
  'special-format': 'telekom', // #E20074
  'custom-task': 'grey darken-4', // #424242
  'custom-service': 'grey darken-4' // #424242
}

export const TASK_LIST_HEXES = {
  scout: '#FFD600',
  control: '#006064',
  assembly: '#311B92',
  disassembly: '#C51162',
  'special-format': '#E20074',
  'custom-task': '#424242',
  'custom-service': '#424242'
}

export const TASK_LIST_TYPES = {
  scout: 'Scouting',
  control: 'Kontrolle',
  assembly: 'Montage',
  disassembly: 'Demontage',
  'special-format': 'Sonderformatdiensleistung',
  'custom-task': 'Benuzterdefinierte Aufgabe',
  'custom-service': 'Sonderdiensleistung'
}

const TASK_LIST_ACTIONS = {
  scout: 'Scouten',
  extra: 'Scouten',
  control: 'Kontrollieren',
  assembly: 'Montieren',
  disassembly: 'Demontieren',
  'special-format': 'Einreichen',
  'custom-task': 'Einreichen',
  'custom-service': 'Einreichen'
}

const TASK_LIST_ACTIONS_PAST = {
  scout: 'Gescouted',
  extra: 'Gescouted',
  control: 'Kontrolliert',
  assembly: 'Montiert',
  disassembly: 'Demontiert',
  'special-format': 'Eingereicht',
  'custom-task': 'Eingereicht',
  'custom-service': 'Eingereicht'
}

export const SUBMISSION_STATUSES = {
  pending: 'Warten auf Genehmigung',
  approved: 'Genehmigt',
  rejected: 'Abgelehnt',
  remaining: 'Offen'
}
const SUBMISSION_STATUS_COLORS = {
  pending: 'warning',
  approved: 'success',
  rejected: 'error',
  remaining: 'grey lighten-2'
}
const SUBMISSION_STATUS_ICONS = {
  approved: 'mdi-check-decagram',
  pending: 'mdi-timer-sand',
  rejected: 'mdi-close-thick'
}

export const CONTROL_CONDITIONS = {
  clean: 'Zustand OK',
  damaged: 'Werbemittel beschädigt',
  missing: 'Werbemittel fehlt',
  cleaned: 'Wurde gereinigt',
  ill: 'Reinigung nicht erfolgreich',
  disassembled: 'Ausgelaufen/Gekündigt',
  no_ad: 'Fehlt/Beschädigt [veraltet]'
}

export const ASSEMBLY_CONDITIONS = {
  true: 'Montiert',
  np: 'Nicht montiert',
  marked: 'Als montiert markiert'
}

export const DISASSEMBLY_CONDITIONS = {
  true: 'Demontiert',
  np: 'Nicht demontiert',
  nf: 'Nicht gefunden',
  marked: 'Als demontiert markiert'
}

export const DISASSEMBLY_TYPES = {
  main: 'Auftragsende',
  extra: 'Außerordentlich'
  // early: 'Frühzeitig stornierte CityCubes',
  // late: 'CityCubes soll hängen bleiben',
}

export const SCOUT_CONDITIONS = {
  true: 'Gescouted',
  nf: 'Nicht gefunden'
}

export const SPECIAL_FORMAT_CONDITIONS = {
  true: 'Montiert',
  np: 'Nicht montiert',
  nf: 'Nicht gefunden'
}

Vue.filter('taskListColor', type => TASK_LIST_COLORS[type])
Vue.filter('taskListIcon', type => TASK_LIST_ICONS[type])
Vue.filter('taskListType', type => TASK_LIST_TYPES[type] || '')
Vue.filter('taskListAction', (task) => {
  let action = TASK_LIST_ACTIONS[task.type] || ''
  if (task.disassembly?.objectId?.split('-')[0] === 'SpecialFormat') {
    action = `Sonderformat ${action}`
  }
  return action
})

Vue.filter('taskListStatusColor', ({ status, counts }) => {
  if (status === 3) {
    const { approved, completed, total } = counts
    if (approved === total) {
      return 'success'
    }
    if (completed === total) {
      return 'yellow darken-3'
    }
    return 'yellow'
  }
  if (status >= 4) {
    return 'success'
  }
  if (status === 0) {
    return 'white'
  }
  if (status === 0.1) {
    return '#ebebeb'
  }
  if (status === 1) {
    return '#c3c3c3'
  }
  if (status === 2) {
    return '#6dd4ffbd'
  }
})

const taskListPastAction = function(type, lower = false) {
  const text = (TASK_LIST_ACTIONS_PAST[type] || '')
  return lower ? text.toLowerCase() : text
}
Vue.filter('taskListPastAction', taskListPastAction)

Vue.filter('taskListStatus', (status, showArchived) => {
  let statusText = store.getters.taskListStatuses?.[status || 0]
  if (showArchived) {
    statusText += ' & Archiviert'
  }
  return statusText
})
Vue.filter('fieldworkStatus', status => store.state.dictionary.fieldworkStatuses?.[status || 0])
Vue.filter('submissionStatus', status => SUBMISSION_STATUSES[status])
Vue.filter('submissionStatusColor', status => SUBMISSION_STATUS_COLORS[status])
Vue.filter('submissionStatusIcon', status => SUBMISSION_STATUS_ICONS[status])
Vue.filter('controlCondition', submission => CONTROL_CONDITIONS[submission?.condition || 0])
Vue.filter('assemblyCondition', submission => ASSEMBLY_CONDITIONS[(submission?.condition || submission?.result || 0)])
Vue.filter('disassemblyCondition', submission => DISASSEMBLY_CONDITIONS[submission?.condition || 0])
Vue.filter('specialFormatCondition', submission => SPECIAL_FORMAT_CONDITIONS[(submission?.condition)])
Vue.filter('disassemblyStatus', condition => DISASSEMBLY_CONDITIONS[condition || 0])
Vue.filter('disassemblyType', type => DISASSEMBLY_TYPES[type])

Vue.mixin({
  methods: {
    taskListPastAction
  }
})
