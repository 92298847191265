export const state = () => ({
  highlighted: null
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  HIGHLIGHT_FEATURE(state, feature) {
    state.highlighted = feature
  }
}

// actions
export const actions = {
  highlight({ commit }, feature) {
    commit('HIGHLIGHT_FEATURE', feature)
  },
  discard({ commit }) {
    commit('HIGHLIGHT_FEATURE', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
