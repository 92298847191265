import 'intersection-observer'

import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import '@/plugins'
import '@/filters'
import '@/styles'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/errors'
import './registerServiceWorker'

const mode = process.env.VUE_APP_MODE
Vue.config.productionTip = false
Vue.prototype.$siteTitle = 'Rheinkultur'
Vue.prototype.$eventBus = new Vue()
Vue.prototype.$mode = mode

// dev favicon
if (mode !== 'production') {
  const links = document.querySelectorAll('link[href^="/img/icons"]')
  links.forEach(link => link.setAttribute('href', '/dev.png'))
  Vue.prototype.$siteTitle = mode.toUpperCase()
}

function initApp() {
  const maintenance = process.env.VUE_APP_MAINTENANCE_MODE
  if (maintenance) {
    document.getElementsByTagName('html')[0]?.classList?.add('maintenance')
    document.getElementsByTagName('html')[0]?.classList?.remove('loading')
    return
  }

  const fjs = document.getElementsByTagName('script')[0]
  if (!document.getElementById('google-maps-script')) {
    const js = document.createElement('script')
    js.id = 'google-maps-script'
    js.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}&libraries=places,geometry&callback=Function.prototype`
    fjs.parentNode.insertBefore(js, fjs)
  }

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}

initApp()
