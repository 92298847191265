import Vue from 'vue'
import { sum, round } from 'lodash'

export const price = (amount, decimalCount = 2, decimal = ',', thousands = '.', suffix = ' €') => {
  decimalCount = Math.abs(decimalCount)
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount
  const negativeSign = amount < 0 ? '-' : ''
  const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
  const j = (i.length > 3) ? i.length % 3 : 0
  return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '') + suffix
}

const percent = (value, decimals = 2) => {
  if (value === null || value === undefined) {
    return ''
  }
  if (value < 1 && value > 0) {
    value = (value * 100).toFixed(decimals)
  }
  return value + '%'
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / k ** i).toFixed(dm)) + ' ' + sizes[i]
}

const fileSize = function(size) {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
}

const roundPrice = amount => round(amount, 2)

export const number = value => typeof value === 'number' ? new Intl.NumberFormat('de-DE').format(value) : '-'
const distanceInKm = value => value.toFixed(1) + 'km'

export const plural = (value, count, add = 's') => {
  if (count === 1) {
    return value
  }
  return value + add
}

export const pluralize = (value, singular, plural, zero) => {
  if (typeof value !== 'number') {
    value = value?.length || 0
  }
  if (value === 1) {
    return number(value) + ' ' + singular
  }
  if (value === 0 && zero) {
    return number(value) + ' ' + zero
  }
  return number(value) + ' ' + plural
}

Vue.mixin({
  filters: {
    number,
    price,
    percent,
    formatBytes,
    fileSize,
    distanceInKm,
    sum,
    plural,
    pluralize
  },
  methods: {
    number,
    price,
    roundPrice,
    pluralize
  }
})
