import store from '@/store'
import router from '@/router'
import { getUserRoutes } from '@/router/routes/wawi'

export default async(to, from, next) => {
  if (!store.state.user && to.name !== 'login') {
    return next({ name: 'login', query: { redirect: to.fullPath === '/' ? undefined : to.fullPath } })
  }
  if (store.state.user && !store.state.initialized) {
    // register auth routes
    const addRoute = getUserRoutes(store.state.user.accType)
    addRoute && router.addRoute(addRoute)
    await store.dispatch('initialize')
  }

  if (store.state.user && to.name === 'login') {
    return next({ name: 'index' })
  }

  // check permissions
  if (to.meta.permissions) {
    const hasPermission = to.meta.permissions.every(permission => store.state.user.permissions?.includes(permission))
    if (!hasPermission) {
      return next({ name: 'index' })
    }
  }

  next()
}
