import store from '@/store'

export default async(to, from, next) => {
  // if marklist is selected make sure to add it into query parameters
  if (!to.query.ml && store.getters['marklist/mlKey'] && store.getters['marklist/hasCubes']) {
    return next({
      ...to,
      query: {
        ...to.query,
        ml: store.getters['marklist/mlKey']
      }
    })
  }
  // discard current selected if query has different marklist
  if (to.query.ml && to.query.ml !== store.getters['marklist/mlKey']) {
    await store.dispatch('marklist/discard')
  }
  next()
}
