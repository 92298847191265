import Parse from '@/plugins/parse'
import store from '@/store'

export default async(to, from, next) => {
  if (store.state.user) {
    if (to.name === 'choose-password') {
      await Parse.User.logOut()
      window.location.reload()
    }
    return next({ name: 'index' })
  }
  next()
}
