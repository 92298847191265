import { v1 as uuidv1 } from 'uuid'
import { normalizeErrorMessage } from '@/errors'
export const state = () => ({
  items: []
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  NEW_SNACKBAR(state, snackbar) {
    snackbar.id = snackbar.id || uuidv1()
    state.items.push(snackbar)
  },
  DISMISS_SNACKBAR(state, snackbarId) {
    state.items = state.items.filter(x => x.id !== snackbarId)
  }
}

// actions
export const actions = {
  new: ({ commit }, snackbar) => {
    if (snackbar.error) {
      if (!snackbar.message) {
        snackbar.message = snackbar.error.message
      }
    }
    commit('NEW_SNACKBAR', snackbar)
  },
  error: ({ state, commit }, error) => {
    const errorMessage = normalizeErrorMessage(error)
    // if there is already a snackbar with the same message, don't show it again
    if (state.items.find(x => x.message === errorMessage)) { return }
    commit('NEW_SNACKBAR', {
      type: 'error',
      message: errorMessage,
      timeout: 10000
    })
  },
  warn: ({ commit }, message) => {
    commit('NEW_SNACKBAR', {
      type: 'warning',
      message
    })
  },
  success: ({ commit }, message) => {
    commit('NEW_SNACKBAR', {
      type: 'success',
      message
    })
  },
  dismiss: ({ commit }, snackbarId) => {
    commit('DISMISS_SNACKBAR', snackbarId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
