export const unpackDownloadURL = function(value) {
  if (!value) {
    return {}
  }
  const re = /http(.*)\/files\/(.*)/
  const [,, fullPath] = decodeURIComponent(value).match(re)
  const filename = fullPath.split('/').reverse()[0]
  const ext = filename.split('.')[1]
  return { filename, ext }
}

export const wait = milliseconds => new Promise(resolve => setTimeout(resolve, milliseconds))

export const nestedSearch = (value, query) => {
  if (value.constructor.name === 'DocumentReference') {
    return false
  }
  if (Array.isArray(value)) {
    return value.map(v => nestedSearch(v, query)).includes(true)
  } else if (value && typeof value === 'object') {
    return Object.keys(value).map(v => nestedSearch(value[v], query)).includes(true)
  }
  return String(value).includes(query)
}

export const normalizeEmail = (value) => {
  return value
    .trim()
    .toLowerCase()
}

export const normalizeUsernameFromEmail = (value) => {
  const [username, domain] = value
    .trim()
    .toLowerCase()
    .split('@')
  return [username.replace(/\./g, ''), domain].join('@')
}

export const cityCubesUrl = process.env.VUE_APP_CITY_CUBES_URL
