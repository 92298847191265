export const state = () => ({
  hash: null,
  bookingId: null,
  requestId: null
})

// getters
export const getters = {
}

// mutations
export const mutations = {
  SELECT_BOOKING(state, { id, requestId }) {
    state.hash = id ? '#booking=' + (id || '') + '>' + (requestId || '') : null
    state.bookingId = id
    state.requestId = requestId
  }
}

// actions
export const actions = {
  select({ commit }, { id, requestId }) {
    commit('SELECT_BOOKING', { id, requestId })
  },
  discard({ commit }) {
    commit('SELECT_BOOKING', {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
